import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
export const { NODE_ENV, BUILD_ID, ENVIRONMENT } = publicRuntimeConfig;

const getNextConfigFromEnv = (key) => publicRuntimeConfig[`NEXT__${key}`];

export const { BASE_PATH = '' } = publicRuntimeConfig;
export const BASE_URL = getNextConfigFromEnv('PUBLIC_BASE_URL') || '';
export const ASSET_PREFIX = publicRuntimeConfig.ASSET_PREFIX || BASE_PATH;

export const GTM_ID = getNextConfigFromEnv('GTM_ID');
export const GTM_AUTH = getNextConfigFromEnv('GTM_AUTH');
export const GTM_DEBUG_EVENTS = getNextConfigFromEnv('GTM_DEBUG_EVENTS');

export const GOOGLE_MAPS_KEY = getNextConfigFromEnv('GOOGLE_MAPS_KEY');
export const GOOGLE_MAPS_STATIC_API_URL =
  'https://cdn.qantashotels.com/maps/api/staticmap';

export const SENTRY_DSN = getNextConfigFromEnv('SENTRY_DSN');
export const SNARE_JS_URL = getNextConfigFromEnv('SNARE_JS_URL');

export const SALESFORCE_DEPLOYMENT_ID = '5726F0000004gry';
export const SALESFORCE_ORG_ID = '00D90000000ZL1d';
export const SALESFORCE_LIVE_CHAT_ID = '5736F0000004fUc';
export const SALESFORCE_LIVE_CHAT_HOST =
  'https://d.la1-c2-hnd.salesforceliveagent.com';
// eslint-disable-next-line max-len
export const SALESFORCE_LIVE_CHAT_SCRIPT =
  'https://c.la1-c2-hnd.salesforceliveagent.com/content/g/js/52.0/deployment.js';

export const ADYEN_JS_URL = getNextConfigFromEnv('PUBLIC_ADYEN_JS_URL');
export const ADYEN_ENVIRONMENT = getNextConfigFromEnv(
  'PUBLIC_ADYEN_ENVIRONMENT',
);
export const ADYEN_CLIENT_KEY = getNextConfigFromEnv('PUBLIC_ADYEN_CLIENT_KEY');
export const ADYEN_QEPG_CLIENT_KEY = getNextConfigFromEnv(
  'PUBLIC_ADYEN_QEPG_CLIENT_KEY',
);

export const RELEASE = `jetstar-holidays-ui-${BUILD_ID}-${ENVIRONMENT}`;

export const ADOBE_LAUNCH_URL = getNextConfigFromEnv('ADOBE_LAUNCH_URL');

export const SANITY_PROJECT_ID =
  getNextConfigFromEnv('SANITY_PROJECT_ID') || 'sanity-project-id';
export const SANITY_DATASET =
  getNextConfigFromEnv('SANITY_DATASET') || 'sanity-dataset';

export const AUTH_API_URL =
  getNextConfigFromEnv('PUBLIC_AUTH_API_URL') || 'auth_api_url';
export const LSL_AUTH_URL =
  getNextConfigFromEnv('PUBLIC_LSL_AUTH_URL') || 'lsl_auth_url';
export const LSL_USERNAME =
  getNextConfigFromEnv('PUBLIC_LSL_USERNAME') || 'lsl_username';
export const REDIRECT_URL = `${BASE_URL}${BASE_PATH}`;

export const OPTIMIZELY_SDK_KEY = getNextConfigFromEnv('OPTIMIZELY_SDK_KEY');
export const OPTIMIZELY_CUSTOM_SNIPPET_URL = getNextConfigFromEnv(
  'OPTIMIZELY_CUSTOM_SNIPPET_URL',
);

export const BUILDKITE_INFO = {
  BUILDKITE_BUILD_AUTHOR: process.env.BUILDKITE_BUILD_AUTHOR,
  BUILDKITE_BUILD_URL: process.env.BUILDKITE_BUILD_URL,
  BUILDKITE_BUILD_NUMBER: process.env.BUILDKITE_BUILD_NUMBER,
  BUILDKITE_BRANCH: process.env.BUILDKITE_BRANCH,
  BUILDKITE_COMMIT: process.env.BUILDKITE_COMMIT,
  BUILD_TIME: process.env.BUILD_TIME,
  ENVIRONMENT,
};
